import React from "react"
import BlogHelper from "../helpers/blogposts"
import NavigationHelper from "../helpers/navigation"
import BlogItemTag from "./blog_item_tag"

import img_service_cover_bg from "../images/top-bg.png"
import img_aws_service_cover_bg from "../images/aws-bg.png"
import img_a1_cloud_small from "../images/small-logo.png"
import img_aws_small from "../images/aws-small-logo.png"

export default function BlogListItem(props) {

    // New service
    let newServiceClass=""
    if (props.isNew)
        newServiceClass = " budget_div"

    // Post link
    const postLink = NavigationHelper.useGeneratePageURL("fr", 'blogpost', props.slug)

    // Type of blog post
    let bg_img = img_service_cover_bg;
    let small_logo_img = img_a1_cloud_small;
    switch (props.type){
      case 'devops':
        bg_img = img_aws_service_cover_bg;
        small_logo_img = img_aws_small;
        break;
    }

    const imgFileName = BlogHelper.getCDNImgPath(props.preview_url)

    const itemColorsObj = BlogHelper.getItemCardColors(props.category)
    let tags = []
    let filteredTags = []
    props.tags.map((element) => {
      const tagInfo = BlogHelper.getTagInfo(element)
      if (tagInfo){
        filteredTags.push(tagInfo)
      }
    });

    filteredTags.map((tag, idx) => {
      tag.isLast = false;
      if (idx + 1 >= filteredTags.length){
        tag.isLast = true;
      }
      tags.push(<BlogItemTag key={idx} tag={tag} isLink={true} language={props.language}/>);
    });

    return (
        <>
        <div className="col-xl-4 col-lg-4 col-sm-6 col-12">
          <div className={`first-box ${newServiceClass}`}>
            <div className="service-page-box-row-img">
              <a href={postLink} title={props.link_title}>
                <div className="service-page-box-row-img-first blog-hover-img">
                  <img
                    className="bg_img"
                    src={imgFileName}
                    alt={props.preview_alt}
                  />
                  <div className="first-img-text">
                    <img src={itemColorsObj.icon} alt="imh-icon" />
                  </div>
                  <span className="on-hover-text" style={itemColorsObj.style}>
                    {props.categoryTitle}
                  </span>
                </div>
              </a>
            </div>
            <div className="service-page-box-row-text">
              <h4 style={itemColorsObj.style}>{props.categoryTitle}</h4>
              <h3>
                <a href={postLink}title={props.link_title}>
                  {props.title}
                </a>
              </h3>
              <h5>{props.date}</h5>
              <p>
                <div className="excerpt" dangerouslySetInnerHTML={{ __html: props.excerpt }} />
                <a href={postLink} title={props.link_title}>{props.readMore}</a>
              </p>
              <span
                className="blogs_tags"
                style={{
                  display: "block",
                  marginTop: 40,
                  paddingTop: 8,
                  fontSize: 14,
                  borderTop: "1px solid #a9c9d7",
                  color: "#29abe1"
                }}
              >
                {tags}
              </span>
            </div>
          </div>
        </div>
        </>
  )
}
