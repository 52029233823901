import React from "react"
import BlogHelper from "../helpers/blogposts"
import BlogConf from '../conf/blog.json'

export default function BlogNoPost(props) {
    const itemColorsObj = BlogHelper.getItemCardColors("devops")
    const imgFileName = BlogHelper.getCDNImgPath(BlogConf.noPostImage)

    return (
        <>
        <div className="col-xl-4 col-lg-4 col-sm-6 col-12">
          <div className={`first-box`}>
          <div className="service-page-box-row-img">
                <div className="service-page-box-row-img-first blog-hover-img">
                  <img
                    className="bg_img"
                    src={imgFileName}
                    alt={props.preview_alt}
                  />
                  <div className="first-img-text">
                    <img src={itemColorsObj.icon} alt="imh-icon" />
                  </div>
                  <span className="on-hover-text" style={itemColorsObj.style}>
                    {props.categoryTitle}
                  </span>
                </div>
            </div>
            <h3 className="noPost">{props.message}</h3>
          </div>
        </div>
        </>
  )
}
