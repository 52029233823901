/**
 * This helper object centralizes the formatting related to blog posts
 */

 import tagInfo from "../blog/tags.json"
 import img_service_cover_bottom_1 from "../images/service01.jpg"
 import img_service_cover_bottom_2 from "../images/service02.jpg"
 import img_service_cover_bottom_3 from "../images/service03.jpg"
 import img_service_cover_bottom_4 from "../images/service04.jpg"
 import img_service_cover_bottom_5 from "../images/service05.jpg"
 import img_a1_icon_blue from "../images/a1cloud-blue-icon.png"
 import img_a1_icon_orange from "../images/a1cloud-orange-icon.png"
 import img_a1_icon_purple from "../images/a1cloud-purple-icon.png"
 import img_a1_icon_yellow from "../images/a1cloud-yellow-icon.png"
 import img_a1_icon_green from "../images/a1cloud-green-icon.png"

export default {
    getItemCardColors: function(category){
        let a1_icon, service_bottom, category_txt_style
        switch (category){
            case 'devops':
                category_txt_style={}
                a1_icon = img_a1_icon_blue
                service_bottom = img_service_cover_bottom_1
            break;
            case 'review':
                category_txt_style={color: "#e48b00"}
                a1_icon = img_a1_icon_orange
                service_bottom = img_service_cover_bottom_5
            break;
            case 'sre':
                category_txt_style={color: "#805597"}
                a1_icon = img_a1_icon_purple
                service_bottom = img_service_cover_bottom_2
            break;
            case 'cloud':
                category_txt_style={color: "#a69c00"}
                a1_icon = img_a1_icon_yellow
                service_bottom = img_service_cover_bottom_3
            break;
            case 'security':
                category_txt_style={color: "#00938c"}
                a1_icon = img_a1_icon_green
                service_bottom = img_service_cover_bottom_4
            break;
            default:
                category_txt_style={}
                a1_icon = img_a1_icon_blue
                service_bottom = img_service_cover_bottom_1
            break;
        }

        return {
            style: category_txt_style,
            icon: a1_icon,
            bottom: service_bottom
        }
    },
    getTagInfo: function(tagId){
        let info;
        for (const tag in tagInfo){
            if (tagInfo[tag]['id'] == tagId){
                info = tagInfo[tag];
            }
        }
        return info;
    },
    getCDNImgPath: function(imgUrl){
        // Get the link to the picture on the CDN
        let imgFileName = imgUrl.split('/').slice(-1)[0];
        const newFileName = imgFileName.replace(/\..+$/, ".webp");
        return `${process.env.GATSBY_CDN_URL}/blog-assets/img/${newFileName}`
    }

}
