import React from "react"
import BlogTagLink from "./blog_tag_link"
import BlogTags from "../blog/tags.json"


export default function BlogTagList(props) {
    let tags = []
    let postTagIds = []
    let blogTagIds  = []
    BlogTags.map((tag) => {
      blogTagIds.push(tag.id)
    })

    // Filter the list on the post's tags
    try {
      if (props.filterList != undefined && props.filterList.length > 0){
        postTagIds = blogTagIds.filter(value => {
          return props.filterList.includes(parseInt(value))
        });
      }
      else {
        postTagIds = blogTagIds
      }
    } catch (err){
      console.log(err)
      postTagIds = blogTagIds
    }

    for (let a=0; a < postTagIds.length; a++){
      // Add a comma ?
      const needComma = a < (postTagIds.length -1)
      

      let tagName
      BlogTags.map((tag) => {
        if (tag.id == postTagIds[a]){
          tagName = tag.name
        }
      })
      tags.push(<li><BlogTagLink language={props.language} tagTitle={tagName} needComma={needComma}/></li>)
    }

    return (
        <>
        <ul>
          {tags}
        </ul>
        </>
    )
}