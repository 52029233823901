import React from "react"
import BlogHelper from "../helpers/blogposts"
import BlogItemTag from "./blog_item_tag"
import BlogCategoryList from "./blog_category_list"
import BlogTagList from "./blog_tag_list"

export default function BlogListSidebar(props) {
    const itemColorsObj = BlogHelper.getItemCardColors(props.category)

    let tags = [];
    props.tags.map((element, index) => {
      const tagInfo = BlogHelper.getTagInfo(element)
      if (tagInfo){
        tagInfo.isLast = false;

        if (index + 1 == props.tags.length){
          tagInfo.isLast = true;
        }
        tags.push(<BlogItemTag key={index} tag={tagInfo} />);
      }
    });

    return (
        <>
        <div className="col-xl-4 col-lg-4 col-sm-6 col-12">
      <div className="service-page-box-row first-box">
        <div className="service-page-box-row-text">       
          <div className="all-filter">
            <div className="blog-page-filter">
            <h2>{props.sidebarNavTitle}</h2>
            <h3>{props.sidebarCategoriesTitle}</h3>
              <ul>
                <BlogCategoryList
                  language={props.language}
                />
              </ul>
            </div>

            <div className="blog-page-filter">
            <h3>{props.sidebarTagsTitle}</h3>
              <ul className="mots">
                <BlogTagList 
                  language={props.language}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
        </>
    )
}