import React from "react"
import BlogCategoryLink from "./blog_category_link"
import BlogCategories from "../blog/categories.json"


export default function BlogCategoryList(props) {
    let categories = [];
    const blogCatIds = Object.keys(BlogCategories)
    for (let a=0; a < blogCatIds.length; a++){
      const categoryTitle = BlogCategories[blogCatIds[a]][props.language]
      try {
        categories.push(<li><BlogCategoryLink language={props.language} categoryTitle={categoryTitle}/></li>)
      } catch (err){
        console.error('Error while generating category list')
      }
    }

    return (
        <>
        <ul>
          {categories}
        </ul>
        </>
    )
}